<template>
  <div>
    <div class=" row m-b-5">
      <div class="col-md-6">
        <h4>{{ $t('video.select') }}</h4>
      </div>
      <div class="col-md-6 text-right">
        <button
          type="button"
          class="btn btn-default"
          @click="close"
        >
          {{ $t('buttons.back') }}
        </button>
      </div>
    </div>
    <app-video-list
      show-select-button
      disable-actions
      disable-breadcrumb
      @select-record="selectVideo"
    >
    </app-video-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Config from '../../config'
import DamService from '../../services/dam/DamService'
import VideoList from '../video/VideoSimpleTableList'

export default {
  name: 'VideoList',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      search: {
        id: '',
        title: ''
      },
      dataTableConfig: {
        fields: {
          id: 'Id',
          type: 'Type',
          'expanded.previewImage': 'Preview',
          'meta.title': 'Title',
          status: 'Status',
          createdAt: 'Created at',
          createdBy: 'Created by'
        },
        actions: {
          detail: 'video_detail'
        },
        render: {
          type: function (data) {
            if (data === 'youtube') {
              return '<i class="fab fa-youtube" title="Youtube"></i>'
            }
            if (data === 'ooyala') {
              return '<i class="fas fa-video" title="Ooyala"></i>'
            }
            if (data === 'livebox') {
              return '<i class="fas fa-video" title="Livebox"></i>'
            }
            return data
          },
          hidden: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          'expanded.previewImage': function (data) {
            if (data) {
              return '<img src="' + DamService.getImagePath(data.damMediaEmbed.filename) + '" width="100">'
            }
            return ''
          }
        }
      },
      selectedVideo: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    videos () {
      return this.$store.getters['video/list']
    },
    totalCount () {
      return this.$store.getters['video/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['video/totalCount'] / Config.defaults.limit)
    }
  },
  components: {
    appVideoList: VideoList
  },
  methods: {
    getVideos () {
      this.$store.dispatch('video/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('video/setPage', this.page)
      this.getVideos()
    },
    selectVideo (video) {
      if (this.multiple === false) {
        this.selectedVideo = null
      }
      this.selectedVideo = video[0]
      this.addVideo()
    },
    addVideo () {
      this.$emit('set-data', this.selectedVideo)
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }
  },
  beforeMount () {
    this.$store.commit('video/setPage', 1)
    this.$store.commit('video/resetFilter')
    this.$store.dispatch('video/fetch')
  }
}
</script>

<style lang="scss">

</style>
