<template>
  <article
    class="timeline-article"
    draggable="true"
    @dragstart="dragStart($event, 1)"
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <TooltipSlot
      v-if="showTooltip && item.type === 'video' && boxWidth <= 250"
      title="tooltip"
      position="top"
      custom-info-class="tooltip-image"
    >
      <template #title>
        <img
          :src="getImagePath(item.videoInfo)"
          width="100"
          class="m-1"
          style="width: 12rem;"
        >
      </template>
    </TooltipSlot>
    <div class="timeline-article__main-content">
      <div class="d-flex">
        <img
          v-if="item.type === 'video' && boxWidth > 250"
          :src="getImagePath(item.videoInfo)"
          width="100"
          class="mb-1 mr-2"
          style="max-width: 4rem; max-height: 2.3rem;"
        >
        <h6 class="timeline-article__heading">
          <!--        <img-->
          <!--          v-if="item.externalSiteName && item.type === 'article'"-->
          <!--          :src="getSiteLogo(item.externalSiteName)"-->
          <!--        >-->
          <span class="timeline-article__heading-text">
            <template v-if="!item.alternativeTitle">{{ item.title }}</template>
            <template v-if="item.alternativeTitle">{{ item.alternativeTitle }}</template>
            <strong class="timeline-article__strong-text" v-if="item.type === 'external'">EXT</strong>
          </span>
        </h6>
      </div>
      <div class="timeline-article__informations">
        <div class="timeline-article__creation">
          <div class="timeline-article__time">
            <i class="fa fa-clock"></i> {{ item.publishedSince | prettyTime }} - {{ item.publishedUntil | prettyTime }}
          </div>
          <span v-if="item.copied">
            <i class="fa fa-copy"></i>
          </span>
          <div class="timeline-article__created-by">
            <i class="fa fa-plus-circle"></i> {{ reversedName(getUserNameById(item.createdBy)) }}
          </div>
          <div v-if="item.modifiedBy !== null" class="timeline-article__modified-by">
            <i class="fa fa-edit"></i> {{ reversedName(getUserNameById(item.modifiedBy)) }}
          </div>
        </div>
        <div class="timeline-article__metrics" v-show="boxWidth > 250 && !ctrRealtimeDataAreEmpty">
          <span class="timeline-article__metric">
            Recirculation: {{ getRecirculation(ctrRealtimeData.bounceRate) | formatPercentage }}
          </span>
          <span class="timeline-article__metric">
            Avg. reading t: {{ ctrRealtimeData.readingTime | formatSeconds('m:ss') }}
          </span>
          <span class="timeline-article__metric timeline-article__metric--strong">
            CTR: {{ ctrRealtimeData.ctr * 100 | formatNumber }}%
          </span>
        </div>
      </div>
<!--      <span v-if="item.fbShared" class="fab fa-facebook fb-shared" :title="$t('hpTopicOffer.fb_shared')"></span>-->
      <div class="timeline-article__draggable-btn">
        <BtnDraggable />
      </div>
    </div>
    <div class="timeline-article__btn-area">
      <BtnEdit :title="$tc('buttons.edit')" data-test="hp_item_edit" @click="$emit('show-edit-modal', item)" />
      <BtnInfo :title="$tc('buttons.info')" data-test="hp_item_info" @click="$emit('show-info-modal', item)" />
      <BtnCopy :title="copyButtonTooltip" data-test="hp_item_copy" @click="$emit('copy-item', item); copyToClipboard($event);" />
      <BtnDelete
        :title="$tc('buttons.delete_button')"
        data-test="hp_item_delete"
        @click="$emit('show-delete-modal', item)"
      />
    </div>
  </article>
</template>

<script>
import TooltipSlot from '../../tooltip/TooltipSlot.vue'
import ClipboardService from '@/services/ClipboardService'
import NotifyService from '@/services/NotifyService'
import BtnCopy from '@/components/buttons/BtnCopy'
import BtnEdit from '@/components/buttons/BtnEdit'
import BtnInfo from '@/components/buttons/BtnInfo'
import BtnDelete from '@/components/buttons/BtnDelete'
import UserService from '@/services/user/UserService'
import BtnDraggable from '@/components/buttons/BtnDraggable'
import DamService from '@/services/dam/DamService'
import _ from 'lodash'

export default {
  name: 'TimelineArticle',
  components: {
    TooltipSlot,
    BtnDraggable,
    BtnCopy,
    BtnEdit,
    BtnInfo,
    BtnDelete
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    contentBlock: {
      type: Object
    },
    boxTimeLength: {
      type: Number,
      required: true
    },
    boxWidth: {
      type: Number,
      required: true
    },
    timelineBoxAndPosition: {
      type: Object
    }
  },
  data () {
    return {
      showTooltip: false,
      ctrRealtimeData: {}
    }
  },
  computed: {
    copyButtonTooltip () {
      const tooltip = `${this.$tc('hp.copy_article')}`
      return this.item.type === 'video' ? `${this.$t('article.copyId')} - ${this.item.video}\n` + tooltip : tooltip
    },
    ctrRealtimeDataLoading () {
      return this.$store.state.contentBlockItem.realtimeCtrDataLoading
    },
    ctrRealtimeDataAreEmpty () {
      return _.isEmpty(this.ctrRealtimeData)
    }
  },
  methods: {
    copyToClipboard (event) {
      if (this.item.type === 'video') {
        ClipboardService.copy(this.item.video, event)
        NotifyService.setSuccessMessage(this.$t('notify.id_was_copied'))
      }
    },
    loadRealtimeCtrData () {
      const box = this.$store.state.contentBlockItem.realtimeCtrData.filter(
        item => item.box === this.timelineBoxAndPosition.timelineBox
      )[0]
      if (box?.results) {
        const realtimeArticleData = box.results.find(item =>
          item.documentId === this.item.articleDocumentId && item.position === this.timelineBoxAndPosition.position)
        if (realtimeArticleData) {
          this.ctrRealtimeData = realtimeArticleData
        }
      }
    },
    dragStart () {
      this.$emit('copy-dragged-item', this.item)
    },
    getSiteLogo (externalSiteName) {
      return '/img/sites_logo/' + externalSiteName + '.png'
    },
    getImagePath (videoInfo) {
      if (videoInfo && videoInfo.previewImage) {
        return DamService.getImagePath(videoInfo.expanded.previewImage.damMediaEmbed.filename)
      }
    },
    getUserNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    getStatusIcon (status) {
      return ['fa-arrow-down', 'fa-dot-circle', 'fa-arrow-up'][status + 1]
    },
    getRecirculation (item) {
      return 1 - (item ?? 0)
    },
    reversedName (fullName) {
      return isNaN(fullName) ? fullName.split(' ').reverse().join(' ') : fullName
    }
  },
  mounted () {
    if (!this.ctrRealtimeDataLoading) {
      this.loadRealtimeCtrData()
    }
  },
  watch: {
    '$store.state.contentBlockItem.realtimeCtrData' () {
      this.loadRealtimeCtrData()
    }
  }
}
</script>

<style scoped lang="scss">
  .timeline-article {
    position: absolute;
    top: 1px;
    bottom: 1px;
    background: #e5e5e9;
    border-left: 6px solid #6599FE;
    padding: 10px;
    overflow: unset;
    transition: background 200ms;
    &:hover {
      background: #E3EAF0;
      .timeline-article__btn-area {
        bottom: rem(5px);
        display: flex;
      }
      .timeline-article__draggable-btn {
        right: rem(5px);
        display: flex;
      }
    }
    ::v-deep .tooltip-image {
      visibility: visible;
      opacity: 1;
      width: max-content;
    }
    &__main-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__creation {
      .fa {
        max-width: 13px;
      }
    }
    &__informations {
      height: inherit;
      display: flex;
      justify-content: space-between;
    }
    &__inner-container {
      min-width: rem(290px);
    }
    &__heading {
      margin-bottom: 0;
    }
    &__metrics {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &__metric {
      border-left: 1px solid #6e7a8d;
      padding-left: 0.1rem;
      white-space: nowrap;
    }
    &__heading-text,
    &__time,
    &__created-by,
    &__metric,
    &__modified-by {
      font-family: "Roboto", sans-serif;
      font-size: rem(13px);
    }
    &__time,
    &__created-by,
    &__modified-by {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__heading-text {
      font-weight: 700;
      color: #364258;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &__time,
    &__created-by,
    &__metric,
    &__modified-by {
      font-weight: 400;
      color: #6e7a8d;
    }
    &__metric--strong {
      font-weight: 700;
    }
    &__strong-text {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      color: #6599FE;
    }
    &__btn-area {
      display: none;
      flex-wrap: wrap;
      gap: rem(5px);
      width: fit-content;
      position: absolute;
      transition: all 200ms;
      left: rem(10px);
      bottom: rem(-40px);
      z-index: 9;
      button {
        box-shadow: 0 0 20px 3px #c6ccd6;
        max-width: 1.875rem;
        width: 40%;
        aspect-ratio: 1 / 1;
        height: fit-content;
        ::v-deep .btn-copy__content {
          max-width: 1.875rem;
          width: 100%;
          aspect-ratio: 1 / 1;
          height: fit-content;
          svg {
            width: 45%;
          }
        }
      }
      bottom: rem(5px);
    }
    &__draggable-btn {
      display: none;
      position: absolute;
      top: rem(5px);
      right: rem(-40px);
      z-index: 9;
      box-shadow: 0 0 20px 3px #c6ccd6;
      transition: all 200ms;
      border-radius: rem(4px);
    }
    &__metrics {}

    /* Hp box colors by site ********************** */

    &--plus1 {
      border-left: 6px solid #0077c0;
    }

    &--plus7 {
      border-left: 6px solid #0063ac;
    }

    &--emma {
      border-left: 6px solid #ad8b5a;
    }

    &--sarm {
      border-left: 6px solid #ed1c24;
    }

    &--polovnictvo, &--par, &--polovnictvorybarstvo, &--polovnictvoarybarstvo {
      border-left: 6px solid #4b5f38;
    }

    &--sport24 {
      border-left: 6px solid #0079f1;
    }

    &--ncpz, &--novy-cas-pre-zeny {
      border-left: 6px solid #e73159;
    }

    &--autobild, &--autobild-slovensko {
      border-left: 6px solid #bf1a2f;
    }

    &--eva {
      border-left: 6px solid #000000;
    }

    &--adam {
      border-left: 6px solid #8ac650;
    }

    &--zivot {
      border-left: 6px solid #ed1d25;
    }

    &--trend, &--etrend {
      border-left: 6px solid #ac0904;
    }

    &--zdravie, &--izdravie {
      border-left: 6px solid #00a8e6;
    }

    &--lepsiebyvanie, &--lepsie-byvanie {
      border-left: 6px solid #990033;
    }

    &--dobrejedlo, &--dobre-jedlo {
      border-left: 6px solid #f2535b;
    }

    &--zahradkar, &--izahradkar {
      border-left: 6px solid #97bf00;
    }

    &--wanda {
      border-left: 6px solid #e94b98;
    }

    &--zena {
      border-left: 6px solid #dc0024;
    }

    &--magazin {
      border-left: 6px solid #f88f22;
    }

    &--pekne-byvanie, &--ipekne-byvanie {
      border-left: 6px solid #00bfc2;
    }
  }
</style>
