<template>
  <app-module-view>
    <template slot="body">
      <app-video-management-filter>
      </app-video-management-filter>
      <template v-for="contentBlock in contentBlocks">
        <app-content-block-timeline
          v-if="['video', 'articleVideo'].includes(contentBlock.type)"
          :key="contentBlock.id"
          type="video"
          :content-block="contentBlock"
          ref="timelineComponents"
        >
        </app-content-block-timeline>
      </template>
      <div
        v-if="showVideoStats"
        class="row"
      >
        <h3 class="col-lg-12">{{ $t('videoStats.videoplays') }}</h3>
        <template v-for="contentBlock in contentBlocks">
          <app-playlist-chart
            :contentBlock="contentBlock"
            :key="contentBlock.id"
          >
          </app-playlist-chart>
        </template>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import ModuleView from '@/components/ModuleView'
import ContentBlockTimeline from '@/components/contentBlockItem/timeline/Timeline'
import VideoManagementFilter from '../../components/video/videoManagement/VideoManagementFilter'
import PlaylistChart from '../../components/video/videoManagement/PlaylistChart'

export default {
  name: 'VideoManagementView',
  data () {
    return {
      hpTimeStatusInterval: 0
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    contentBlocks () {
      const contentBlocks = this.$store.getters['contentBlock/list']
      return contentBlocks.filter(contentBlock => {
        if (contentBlock.type === 'video' || contentBlock.type === 'videoArticle') {
          return contentBlock
        }
      })
    },
    filter () {
      return this.$store.getters['contentBlock/hpManagementFilter']
    },
    showVideoStats () {
      if (
        this.$store.getters['user/hasRole']('ROLE_SUPER_ADMIN') ||
        this.$store.getters['user/hasRole']('ROLE_VIDEO_ADMIN')
      ) {
        return true
      }
      return false
    }
  },
  components: {
    appModuleView: ModuleView,
    appVideoManagementFilter: VideoManagementFilter,
    appContentBlockTimeline: ContentBlockTimeline,
    appPlaylistChart: PlaylistChart
  },
  methods: {
    updateTimelines () {
      const timelineComponents = this.$refs.timelineComponents
      timelineComponents.forEach(timeline => timeline.updateData())
    },
    init () {
      this.getContentBlocks()
    },
    getContentBlocks () {
      this.$store.commit('contentBlock/setLimit', 30)
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    }
  },
  created () {
    this.init()
  },
  mounted () {
    clearInterval(this.hpTimeStatusInterval)
    this.hpTimeStatusInterval = setInterval(() => {
      this.updateTimelines()
    }, 10000)
  }
}
</script>
