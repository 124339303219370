<template>
  <section class="timeline">
    <div class="timeline__content">
      <Preloader v-if="!dataLoaded" class="timeline__preloader" />
      <div class="timeline__top-bar">
        <div class="timeline__top-bar-1">
          <h4 class="timeline__title">{{ contentBlock.id }}. {{ contentBlock.title }}</h4>
          <Select
            v-if="type !== 'video'"
            v-model="mainCtrSelectValue"
            :options="mainCtrSelect.options"
            no-empty-value
            no-label
            disable-form-group
            id="hp_timeline_boxTimeLength"
            :compact-width="true"
            :compact-height="true"
          />
        </div>
        <div class="timeline__top-bar-2">
          <BtnDetail
            v-if="showContentBlockInfoButton"
            :title="$tc('buttons.content_block_settings')"
            :link-to="{ name: 'contentBlock_edit', params: { id: contentBlock.id }}"
          />
          <BtnRefresh
            :click-handler="getContentBlockItems"
            :data-test="'hp_timeline_refresh'"
            :title="$tc('buttons.refresh')"
          />
          <Select
            v-model="boxTimeLength"
            :options="boxTimeLengths"
            no-empty-value
            no-label
            disable-form-group
            @change="changeBoxTimeLength"
            id="hp_timeline_boxTimeLength"
            :compact-width="true"
            :compact-height="true"
          />
          <div class="timeline__vertical-line"></div>
          <BtnLeft
            :click-handler="timelineBackwards"
            :data-test="'hp_timeline_backwards'"
            :title="$tc('buttons.backward')"
            class="timeline__backwards-btn"
          />
          <BtnRight
            :click-handler="timelineForwards"
            :data-test="'hp_timeline_forwards'"
            :title="$tc('buttons.forward')"
          />
        </div>
      </div>
      <div class="timeline__data">
        <div class="timeline__time-status" :style="hpTimeStatusPosition">
          <span class="timeline__time-status-data">{{ $t('hp.now') }}</span>
        </div>
        <div class="timeline__header">
          <div class="timeline__box timeline__box--first">{{ $t('hp.position') }}</div>
          <div class="timeline__box" v-for="(time, index) in timelineConfig.times" :key="`item-${time + index}`">
            <small class="timeline__date">{{ time | prettyDate }}</small>
            {{ time | prettyTime }}
          </div>
        </div>
        <template v-for="(position, index) in timelineConfig.positions">
          <TimelineRow
            v-if="contentBlock.numberOfItems >= position && isDisabledPosition(position) === false"
            :key="`item-${index}`"
            :position="position"
            :show-create-modal="showCreateModal"
            :timeline-config="timelineConfig"
            :copied-item="copiedItem"
            :paste="paste"
            :type="type"
            :timeline-box-and-position="{ timelineBox, position }"
            @paste-dragged-item="pasteDraggedItem"
          >
            <template
              v-slot:timelineArticle
            >
              <div v-for="(item, index) in contentBlockItems" :key="`item-${item + index}`">
                <TimelineArticle
                  v-if="item.position === position"
                  :class="getClassNameBySite(item)"
                  :style="setArticleBoxPosition(item)"
                  :item="item"
                  :content-block="contentBlock"
                  @show-edit-modal="showEditModal"
                  @show-info-modal="showInfoModal"
                  @show-delete-modal="showDeleteModal"
                  @copy-item="copyItem"
                  :title="`${item.externalSiteName.toUpperCase()} - ${item.title}`"
                  @copy-dragged-item="copyDraggedItem"
                  :box-time-length="boxTimeLength"
                  :box-width="getArticleBoxWidth(item)"
                  :timeline-box-and-position="{ timelineBox, position }"
                  ref="timelineArticleComponents"
                />
              </div>
            </template>
          </TimelineRow>
        </template>
      </div>
    </div>
    <CreateModal
      v-if="createModal && type !== 'video'"
      :content-block-id="contentBlock.id"
      :position="selectedPosition"
      :publishedSince="selectedPublishedSince"
      :publishedUntil="selectedPublishedUntil"
      @change="getContentBlockItems"
      @close="closeCreateModal"
    />
    <CreateVideoModal
      v-if="createModal && type === 'video'"
      :content-block-id="contentBlock.id"
      :position="selectedPosition"
      :publishedSince="selectedPublishedSince"
      :publishedUntil="selectedPublishedUntil"
      @change="getContentBlockItems"
      @close="closeCreateModal"
    />
    <EditModal
      v-if="editModal && type !== 'video'"
      :content-block-id="contentBlock.id"
      :content-block-item="selectedContentBlockItem"
      @change="getContentBlockItems"
      @close="closeEditModal"
    />
    <EditVideoModal
      v-if="editModal && type === 'video'"
      :content-block-id="contentBlock.id"
      :content-block-item="selectedContentBlockItem"
      @change="getContentBlockItems"
      @close="closeEditModal"
    />
    <DeleteModal
      v-if="deleteModal"
      :content-block-item="selectedContentBlockItem"
      @post-delete="getContentBlockItems"
      @close="closeDeleteModal"
    />
    <InfoModal
      v-if="infoModal && type !== 'video'"
      :content-block-item="selectedContentBlockItem"
      @close="closeInfoModal"
    />
    <InfoVideoModal
      v-if="infoModal && type === 'video'"
      :content-block-item="selectedContentBlockItem"
      @close="closeInfoModal"
    />
  </section>
</template>

<script>
import Preloader from '@/components/preloader/Preloader'
import BtnDetail from '@/components/buttons/BtnDetail'
import BtnRefresh from '@/components/buttons/BtnRefresh'
import BtnLeft from '@/components/buttons/BtnLeft'
import BtnRight from '@/components/buttons/BtnRight'
import Select from '@/components/form/select/Select'
import CoreApi from '@/api/core'
import Moment from 'moment'
import CreateModal from '@/components/contentBlockItem/CreateModal'
import CreateVideoModal from '@/components/video/videoManagement/CreateModal'
import EditVideoModal from '@/components/video/videoManagement/EditModal'
import InfoVideoModal from '@/components/video/videoManagement/InfoModal'
import EditModal from '@/components/contentBlockItem/EditModal'
import DeleteModal from '@/components/contentBlockItem/DeleteModal'
import InfoModal from '@/components/contentBlockItem/InfoModal'
import NotifyService from '@/services/NotifyService'
import MediaService from '@/services/media/MediaService'
import TimelineRow from '@/components/contentBlockItem/timeline/TimelineRow'
import TimelineArticle from '@/components/contentBlockItem/timeline/TimelineArticle'

/* Box width in pixels */
const BOX_WIDTH_PX = 140
const BOX_LEFT_OFFSET = 140
const BOX_RIGHT_MARGIN = 1
const BOX_COUNT = 22
const POSITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

export default {
  name: 'Timeline',
  props: {
    type: {
      type: String,
      default: 'article'
    },
    contentBlock: {
      type: Object
    }
  },
  components: {
    TimelineRow,
    Preloader,
    CreateModal,
    CreateVideoModal,
    EditModal,
    EditVideoModal,
    DeleteModal,
    InfoModal,
    InfoVideoModal,
    TimelineArticle,
    BtnDetail,
    BtnRefresh,
    BtnLeft,
    BtnRight,
    Select
  },
  data () {
    return {
      timelineCtr: {},
      dataLoaded: false,
      timelineConfig: {
        positions: POSITIONS,
        times: []
      },
      hpTimeStatusPosition: 'left: 0px',
      hpTimeStatusInterval: 0,
      contentBlockItems: [],
      createModal: false,
      editModal: false,
      deleteModal: false,
      infoModal: false,
      selectedContentBlockItem: {},
      selectedPosition: 1,
      selectedPublishedSince: '',
      selectedPublishedUntil: '',
      boxTimeLength: 1800,
      boxTimeLengths: [
        {
          id: 900,
          title: this.$t('hp.interval_1')
        },
        {
          id: 1800,
          title: this.$t('hp.interval_2')
        },
        {
          id: 3600,
          title: this.$t('hp.interval_3')
        },
        {
          id: 14400,
          title: this.$t('hp.interval_4')
        }
      ],
      timelinePosition: 0,
      timelinePositionCount: 0,
      copiedItem: {},
      draggedItem: {},
      draggedItemPreparedForDelete: {},
      mainCtrSelect: {
        value: 'total',
        options: [
          {
            id: 'total',
            title: this.$t('ctr.total')
          },
          {
            id: 'mobile',
            title: this.$t('ctr.mobile')
          },
          {
            id: 'desktop',
            title: this.$t('ctr.desktop')
          }
        ]
      },
      timelineBrand: '',
      timelineBox: ''
    }
  },
  computed: {
    mainCtrSelectValue: {
      get () {
        return this.$store.state.contentBlockItem.mainCtrSelect.value
      },
      set (data) {
        this.$store.commit('contentBlockItem/SET_MAIN_CTR_SELECT', data)
      }
    },
    showContentBlockInfoButton () {
      return !!this.$store.getters['user/hasRole']('ROLE_SUPER_ADMIN')
    }
  },
  methods: {
    init (showLoading = true) {
      this.timelineBrand = this.contentBlock.identifier.split('_')[0] // e.g. - "plus7"
      this.timelineBox = this.contentBlock.identifier // e.g. - "plus7_hp_clanky"
      this.getContentBlockItems(showLoading)
      this.getTimes()
      this.getHpHistoricalCtrData()
      this.getHpRealtimeCtrData()
    },
    copyItem (item) {
      this.copiedItem = this._.cloneDeep(item)
      const message = `${this.$t('contentBlock.copy.first')} "${item.title}" ${this.$t('contentBlock.copy.last')}`
      NotifyService.setSuccessMessage(message)
    },
    copyDraggedItem (item) {
      this.draggedItem = this._.cloneDeep(item)
      this.draggedItemPreparedForDelete = this._.cloneDeep(item)
    },
    pasteDraggedItem (data) {
      let timeLength = this.boxTimeLength
      if (timeLength < 3600) {
        timeLength = 3600
      }
      this.draggedItem.position = data.position
      this.draggedItem.publishedSince = data.time
      this.draggedItem.publishedUntil = Moment(data.time).add(timeLength, 's').toISOString()
      if (this.draggedItem.imageInfo) {
        MediaService.createMedia(this.draggedItem.imageInfo)
          .then(media => {
            this.draggedItem.image = media.id
            this.draggedItem.imageInfo = media
            this.save(this.draggedItem)
            this.$store.dispatch('contentBlockItem/deleteRecord', this.draggedItemPreparedForDelete)
            this.draggedItemPreparedForDelete = {}
          })
      } else {
        this.save(this.draggedItem)
        this.$store.dispatch('contentBlockItem/deleteRecord', this.draggedItemPreparedForDelete)
        this.draggedItemPreparedForDelete = {}
      }
    },
    paste (position, publishedSince) {
      let timeLength = this.boxTimeLength
      if (timeLength < 3600) {
        timeLength = 3600
      }
      this.copiedItem.position = position
      this.copiedItem.publishedSince = publishedSince
      this.copiedItem.publishedUntil = Moment(publishedSince).add(timeLength, 's').toISOString()
      if (this.copiedItem.imageInfo) {
        MediaService.createMedia(this.copiedItem.imageInfo)
          .then(media => {
            this.copiedItem.image = media.id
            this.copiedItem.imageInfo = media
            this.save(this.copiedItem)
          })
      } else {
        this.save(this.copiedItem)
      }
    },
    getHpHistoricalCtrData () {
      this.$store.dispatch('contentBlockItem/getHpHistoricalCtrData', {
        brand: this.timelineBrand,
        box: this.timelineBox
      })
    },
    getHpRealtimeCtrData () {
      this.$store.dispatch('contentBlockItem/getHpRealtimeCtrData', {
        brand: this.timelineBrand,
        box: this.timelineBox
      })
    },
    getContentBlockItems (showLoading = true) {
      if (showLoading) {
        this.dataLoaded = false
      }
      const dateFrom = this.getStartTime().add(-1, 'days').toISOString()
      const dateTo = this.getEndTime().add(1, 'days').toISOString()
      const url = '/ContentBlockItemWithin?contentBlockId=' + this.contentBlock.id +
        '&startDatetime=' + dateFrom +
        '&endDatetime=' + dateTo
      CoreApi().get(url)
        .then(response => {
          this.contentBlockItems = response.data.data
          this.dataLoaded = true
          this.setHpTimeStatusPosition()
        })
        .catch(error => {
          console.error(error)
        })
    },
    getStartTime () {
      const now = Moment()
      now.add(-now.minutes(), 'm')
      now.add(this.timelinePosition, 's')
      now.set({ second: 0, millisecond: 0 })
      return now
    },
    getEndTime () {
      const endTime = this.getStartTime()
      endTime.add(
        (this.boxTimeLength / 60) * BOX_COUNT,
        'm'
      )
      return endTime
    },
    getTimes () {
      this.boxTimeLength = parseInt(this.boxTimeLength)
      const startTime = this.getStartTime()
      const times = []
      for (let i = 1; i <= BOX_COUNT; i++) {
        times.push(startTime.toISOString())
        startTime.add(this.boxTimeLength / 60, 'm')
      }
      this.timelineConfig.times = times
    },
    changeBoxTimeLength () {
      this.init()
    },
    setArticleBoxPosition (item) {
      const width = this.getArticleBoxWidth(item)
      let style = 'left:' + this.getArticleBoxLeftValue(item) + 'px; width:' + width + 'px;'
      if (width === 0) {
        style = style + 'display:none;'
      }
      return style
    },
    getArticleBoxWidth (item) {
      const startTime = this.getStartTime()
      const publishedSince = Moment(item.publishedSince)
      const publishedUntil = Moment(item.publishedUntil)
      if (publishedUntil.diff(startTime) < 1) {
        return 0
      }
      let diffSeconds = publishedUntil.diff(publishedSince)
      if (publishedSince.diff(startTime) < 1) {
        diffSeconds = publishedUntil.diff(startTime)
      }
      return (diffSeconds / (this.boxTimeLength * 1000)) * BOX_WIDTH_PX - BOX_RIGHT_MARGIN
    },
    getArticleBoxLeftValue (item) {
      const publishedSince = Moment(item.publishedSince)
      const diffSeconds = publishedSince.diff(this.getStartTime())
      const left = (diffSeconds / (this.boxTimeLength * 1000)) * BOX_WIDTH_PX + BOX_LEFT_OFFSET
      if (BOX_LEFT_OFFSET > left) {
        return BOX_LEFT_OFFSET
      }
      return left
    },
    setHpTimeStatusPosition () {
      const diffSeconds = Moment().diff(this.getStartTime())
      const left = (diffSeconds / (this.boxTimeLength * 1000)) * BOX_WIDTH_PX + BOX_LEFT_OFFSET
      this.hpTimeStatusPosition = 'left:' + left + 'px'
    },
    showCreateModal (position, publishedSince) {
      this.selectedPublishedSince = publishedSince
      let timeLength = this.boxTimeLength
      if (timeLength <= 3600) {
        timeLength = 3600
      }
      this.selectedPublishedUntil = Moment(publishedSince).add(timeLength, 's').toISOString()
      this.selectedPosition = position
      this.createModal = true
    },
    closeCreateModal () {
      this.createModal = false
    },
    showEditModal (contentBlockItem) {
      this.selectedContentBlockItem = contentBlockItem
      this.editModal = true
    },
    closeEditModal (contentBlockItemId) {
      this.editModal = false
    },
    showDeleteModal (contentBlockItem) {
      this.selectedContentBlockItem = contentBlockItem
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
    },
    showInfoModal (contentBlockItem) {
      this.selectedContentBlockItem = contentBlockItem
      this.infoModal = true
    },
    closeInfoModal () {
      this.infoModal = false
    },
    timelineForwards () {
      this.timelinePositionCount++
      this.timelinePosition = this.timelinePosition + this.boxTimeLength
      this.getTimes()
      if (this.timelinePositionCount === 7) {
        this.timelinePositionCount = 0
        this.getContentBlockItems()
      }
      this.setHpTimeStatusPosition()
    },
    timelineBackwards () {
      this.timelinePositionCount--
      this.timelinePosition = this.timelinePosition - this.boxTimeLength
      this.getTimes()
      if (this.timelinePositionCount === -7) {
        this.timelinePositionCount = 0
        this.getContentBlockItems()
      }
      this.setHpTimeStatusPosition()
    },
    addOneSecondToPublishedSince (publishedSince) {
      const date = Moment(publishedSince)
      date.add(1, 's')
      return date.toISOString()
    },
    prepareRequest (item) {
      if (item.publishedSince) {
        item.publishedSince = this.addOneSecondToPublishedSince(item.publishedSince)
      }
      return item
    },
    async save (item) {
      this.$store.dispatch('contentBlockItem/create', this.prepareRequest(item))
        .then(() => {
          if (this.$store.getters['contentBlockItem/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.getContentBlockItems()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlockItem/error'])
          }
        })
        .catch(error => console.error(error))
    },
    isDisabledPosition (position) {
      const disabledPositions = this.contentBlock.disabledPositions.split(',')
      return disabledPositions.includes(position.toString())
    },
    getClassNameBySite (item) {
      let siteName = item.externalSiteName.toLowerCase()
      siteName = siteName
        .replace(' ', '-')
        .replace('ľ', 'l')
        .replace('š', 's')
        .replace('č', 'c')
        .replace('ť', 't')
        .replace('ž', 'z')
        .replace('ý', 'y')
        .replace('á', 'a')
        .replace('í', 'i')
        .replace('é', 'e')
        .replace('ú', 'u')
        .replace('ä', 'a')
        .replace('ň', 'n')
        .replace('ô', 'o')
        .replace('ľ', 'l')

      return 'timeline-article--' + siteName
    },
    updateData () {
      this.setHpTimeStatusPosition()
      if (
        this.contentBlock.id === 1 ||
        (this.type === 'video' && [78, 79].includes(this.contentBlock.id))
      ) {
        this.init(false)
      }
    }
  },
  created () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
  .timeline {
    display: block;
    background: #fff;
    padding: rem(20px);
    margin-top: rem(20px);
    &__preloader {
      position: relative;
      top: rem(200px);
      z-index: 100;
    }
    &__header {
      margin-bottom: rem(10px);
      background: #F5F5F9;
      min-height: rem(50px);
      border-radius: rem(6px);
      width: rem(3600px);
      display: flex;
      position: relative;
    }
    &__top-bar {
      display: grid;
      grid-gap: .625rem;
      @media screen and (min-width: 768px) {
        grid-template-columns: max-content max-content;
        justify-content: space-between;
      }
    }
    &__top-bar-1 {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      @media screen and (min-width: 0px) and (max-width: 767px) {
        justify-content: space-between;
      }
    }
    &__top-bar-2 {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }
    &__title {
      font-family: "Roboto", sans-serif;
      font-size: rem(18px);
      font-weight: 700;
      color: #465674;
      margin: 0;
    }
    &__vertical-line {
      margin: 0 rem(10px);
      height: 100%;
      width: 1px;
      background: #D1DBE4;
      @media screen and (min-width: 0px) and (max-width: 767px) {
        display: none;
      }
    }
    &__backwards-btn {
      @media screen and (min-width: 0px) and (max-width: 767px) {
        margin-left: auto;
      }
    }
    &__box {
      font-family: "Roboto", sans-serif;
      font-size: rem(13px);
      font-weight: 600;
      color: #8A96AC;
      padding-left: rem(14px);
      padding-bottom: rem(5px);
      width: rem(140px);
      display: grid;
      align-items: end;
      align-content: flex-end;
      &--first {
        align-items: center;
        justify-content: center;
        align-content: center;
        padding: 0;
        border-right: 1px solid rgba(138, 149, 172, .3);
      }
    }
    &__date {
      font-family: "Roboto", sans-serif;
      font-size: rem(12px);
      font-weight: 500;
      color: #8A96AC;
      display: block;
      margin-bottom: rem(2px);
    }
    &__time-status {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: rem(2px);
      background-color: #f85d6b;
      z-index: 8;
      pointer-events: none;
    }
    &__time-status-data {
      font-family: "Roboto", sans-serif;
      font-size: rem(10px);
      color: #fff;
      font-weight: 400;
      background-color: #f85d6b;
      text-transform: uppercase;
      padding: rem(3px) rem(5px);
      position: absolute;
      top: 0;
      letter-spacing: .2px;
    }
    &__data {
      margin-bottom: 30px;
      margin-top: 20px;
      position: relative;
      // overflow-y: hidden;
      // overflow-x: scroll;
      overflow: unset;
    }
  }
</style>
