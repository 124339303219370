<template>
  <div
    :class="['timeline-add-article', {'timeline-add-article--half': copiedItem.id}]"
  >
    <button
      v-if="copiedItem.id"
      class="timeline-add-article__btn-paste"
      @click="$emit('btn-paste-click', $event)"
      data-test="hp_timeline_paste"
      type="button"
    >
      <IconCopy class="timeline-add-article__copy-icon" />
      <p class="timeline-add-article__desc">{{ type === 'video' ? $t('hp.insert_video') : $t('hp.insert_article') }}</p>
    </button>
    <div
      class="timeline-add-article__btn-plus"
      @click="$emit('btn-plus-click', $event)"
      data-test="hp_timeline_add"
    >
      <i class="fa fa-plus-circle timeline-add-article__plus-icon"></i>
      <p class="timeline-add-article__desc">{{ type === 'video' ? $t('hp.add_video') : $t('hp.add_article') }}</p>
    </div>
  </div>
</template>

<script>
import IconCopy from '@/assets/img/svg/copy.svg?inline'

export default {
  name: 'TimelineAddArticle',
  components: {
    IconCopy
  },
  props: {
    type: {
      type: String,
      default: 'article'
    },
    copiedItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .timeline-add-article {
    background: #F2F6FC;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    left: 0;
    border: 1px solid #6599FE;
    padding: 0;
    // display: block;
    display: none;
    &__btn-plus,
    &__btn-paste {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      border: none;
      background: #F2F6FC;
      cursor: pointer;
      height: 100%;
      .timeline-add-article--half & {
        height: 50%;
      }
      &:hover {
        background: #E3EAF0;
      }
    }
    &__btn-paste {
      border-bottom: 1px solid #6599FE;
    }
    &__copy-icon {
      width: rem(12px);
      height: auto;
      fill: #6599FE;
    }
    &__plus-icon {
      width: rem(15px);
      height: rem(15px);
      background: #fff;
      border-radius: 50%;
      color: #6599FE;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: rem(6px);
    }
    &__desc {
      font-family: "Roboto", sans-serif;
      font-size: rem(12px);
      font-weight: 500;
      color: #6599FE;
      margin: 0;
    }
  }
</style>
